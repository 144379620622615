import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink,useLocation } from 'react-router-dom';   
import { API_URL, getHeader } from "../config"
import axios from "axios"
export const form = async (formData, header) => {
    try {
        console.log('Sending request to:', `${API_URL}/api/form/${formData.schoolcode}`);
        console.log('Form data:', formData);
        console.log('Header:', header);

        const { data } = await axios.post(`${API_URL}/api/form/${formData.schoolcode}`, formData, { headers: header });
        console.log('Response data:', data);
        return data;
    } catch (error) {
        console.error('Error in form submission:', error);
        if (error.response) {
            console.error('Error response data:', error.response.data);
            console.error('Error response status:', error.response.status);
        }
        throw error;
    }
};
const Header = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
        schoolcode: "GHSS" // or other school code
    });
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = "Name is required";
        if (!formData.email) {
            newErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Email is invalid";
        }
        if (!formData.message) newErrors.message = "Message is required";
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        try {
            const header = getHeader();
            console.log('Submitting form with data:', formData);
            const response = await form(formData, header);
            console.log('Form submitted successfully:', response);
            setSubmitted(true);
            setFormData({ name: "", email: "", phone: "", dob: "", fatherName: "", class: "", message: "", schoolcode: formData.schoolcode });
            setErrors({});
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };


    const marqueeRef = useRef(null);

    const handleMouseOver = () => {
        marqueeRef.current.stop();
    };

    const handleMouseOut = () => {
        marqueeRef.current.start();
    };
    // const MarqueeComponent = ({ children }) => {


    //     const handleMouseOver = () => {
    //       setIsHovered(true);
    //     };

    //     const handleMouseOut = () => {
    //       setIsHovered(false);
    //     };


    //   };

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsOpen(window.innerWidth >= 991);
        };

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [active, setActive] = useState("");

    const handlePopup = () => {

        setActive("active");
    }
    const handleClose = () => {

        setActive("");
    }


    return (

        <div className="header ">
            {/* <div className="">
                <div className="container-fluid pd-0">
                    <div className="row">
                        <div className='col-md-2 highlight-tab'>
                           
                        </div>
                        <div className='col-md-8'>
                        
 

                        </div>
                        <div className='col-md-2 highlight-tab'>
                            <p className="d-flex space-even highlight-blk">
                                <a href="https://twitter.com/stthomasschool_?lang=en" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/twitter.png'></img></a>
                                <a href="https://www.instagram.com/st.thomasschool.mainpuri/" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/insta.png'></img></a>
                                <a href="https://www.youtube.com/watch?v=6-sKinVruzI" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/facebook.png'></img></a>
                                <a href="https://www.youtube.com/watch?v=6-sKinVruzI" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/linkdin.png'></img></a>
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="header-blk">
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-xxl-3 col-xl-3 col-lg-12'>
                            <Link to="/">
                                <div className='logo'>
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/Logo.png" className='img-fluid'></img>
                                </div>
                            </Link>
                        </div>
                        <div className='col-xxl-9 col-xl-9 col-lg-12'>
                            <div className="contact-tab">
                                <div><div className="button"><span>Admission Info </span><div className='img'> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/icon1.png"></img></div></div></div>
                                <div><div className="button"><span>parent login</span> <div className='img'> <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/icon2.png"></img></div></div></div>
                                <div><div className="button light padding"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/icon3.png"></img> &nbsp;<span>Blog </span></div></div>
                                <div className="online-reg" onClick={handlePopup}><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/online-reg.png" /></div>

                                <div className="kinderbtn"><Link to="/KinderGarten"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/kinder.gif"></img> &nbsp;<span> KINDERGARTEN</span> </Link></div>
                            </div>
                        </div>
                        <div className='menu-tab'>
                            <button className="menu-toggle" onClick={toggleMenu}><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/menu.png" /></button>
                        </div>


                    </div>
                </div>
            </div>
            <div className={`form-section ${active}`}>
                <div className='form-block'>

                    {submitted && <p className="success-message">Thank you for your message!</p>}
                    <div className='close' onClick={handleClose}>
                        <i class="bi bi-x-lg"></i>
                    </div>
                    <div className='form-heading'>
                        <h3>Register Here</h3>
                    </div>
                    <form className='form-feilds' onSubmit={handleSubmit}>
                        <div>
                            <label>Student Name</label>
                            <input type="text" placeholder='Student Name' name="name" value={formData.name} onChange={handleChange} />
                        </div>
                        <div>
                            <label>Date Of Birth</label>
                            <input type="date" placeholder='DOB' name="dob" value={formData.dob} onChange={handleChange} />
                        </div>
                        <div>
                            <label>Phone Number</label>
                            <input type="tel" placeholder='Phone Number' name="phone" value={formData.phone} onChange={handleChange} />
                        </div>
                        <div>
                            <label>Email Id</label>
                            <input type="email" placeholder='Email Id' name="email" value={formData.email} onChange={handleChange} />
                        </div>
                        <div>
                            <label>Father Name</label>
                            <input type="text" placeholder='Father Name' name="fatherName" value={formData.fatherName} onChange={handleChange} />
                        </div>
                        <div>
                            <label>Select Class</label>
                            <select name="class" value={formData.class} onChange={handleChange}>
                                <option value="1">I</option>
                                <option value="2">II</option>
                                <option value="3">III</option>
                                <option value="4">IV</option>
                                <option value="5">V</option>
                                <option value="6">VI</option>
                                <option value="7">VII</option>
                                <option value="8">VIII</option>
                                <option value="9">IX</option>
                                <option value="10">X</option>
                                <option value="11">XI</option>
                                <option value="12">XII</option>
                            </select>
                        </div>
                        <div className="message-feild">
                            <label>Message</label>
                            <textarea placeholder='Enter Your Message' name="message" value={formData.message} onChange={handleChange}></textarea>
                        </div>
                        <div className="submit">
                            <input type="submit" value="Submit" />
                            {/* <input type="button" value="Clear" /> */}
                        </div>
                    </form>
                </div>
            </div>
            <div className="menu">

                {isOpen && (
                    <div>
                        <div class="logo">
                            <NavLink to="/"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/Logo.png" /></NavLink>
                        </div>
                        <ul className="submenu  animate__animated animate__fadeIn animate__delay-1s">

                            <li>about
                                <ul className="sub-submenu">
                                    <li><NavLink to='/About'>About</NavLink></li>
                                    <li><NavLink to='/Vision'>Vision</NavLink></li>
                                    <li><NavLink to='/Mission'>Mission</NavLink></li>
                                    <li><NavLink to='/DirectorMessage'>Director's Desk</NavLink></li>
                                    <li><NavLink to='/PrincipalMessage'>Principal's Desk</NavLink></li>
                                    <li><NavLink to='/RulesRegulations'>Rules & Regulations</NavLink></li>
                                </ul>
                            </li>
                            <li>
                                Facilities

                                <ul className="sub-submenu">
                                    <li><NavLink to='/'>Sports</NavLink></li>
                                    <li><NavLink to=''>Transport</NavLink></li>
                                    <li><NavLink to=''>Fully Air Conditioned Classroom</NavLink></li>
                                    <li><NavLink to=''>Free coaching for IIT’s</NavLink></li>
                                    <li><NavLink to=''>Cultural & Dance</NavLink></li>
                                    {/* <li><NavLink to='/Infrastructure'>Infrastructure</NavLink></li> */}

                                </ul>
                            </li>
                            <li>
                                Courses

                                <ul className="sub-submenu">
                                    <li><NavLink to='/'> Stream Offered For XI<sup>th</sup> Class</NavLink></li>
                                    <li><NavLink to=''> Non Medical</NavLink></li>
                                    <li><NavLink to=''>Medical</NavLink></li>
                                    <li><NavLink to=''>Commerce</NavLink></li>
                                    <li><NavLink to=''>Humanities</NavLink></li>
                                </ul>
                            </li>
                            <li>
                                Gallery

                                <ul className="sub-submenu">
                                    <li><NavLink to='/VideoGallery'> Video Gallery</NavLink></li>
                                    <li><NavLink to='/Activites'>Activities</NavLink></li>
                                    <li><NavLink to='/Competition'>Competition</NavLink></li>
                                    <li><NavLink to='/Assemblies'>Assemblies </NavLink></li>
                                    <li><NavLink to='/Achivement'>Achievements </NavLink></li>
                                    <li><NavLink to='/Celebrations'>Celebrations </NavLink></li>
                                    <li><NavLink to='/Sports'>Sports</NavLink></li>

                                </ul>
                            </li>

                            {/* <li>
                                admissions
                                <ul className="sub-submenu">
                                    <li><NavLink to='/'>Syllabus</NavLink></li>
                                    <li><NavLink to='/'>School Timings</NavLink></li>
                                </ul>
                            </li>

                           
                            <li>
                                activities
                                <ul className="sub-submenu">
                                    <li><NavLink to='/'>Primary Wing </NavLink></li>
                                    <li><NavLink to='/'>Clubs and Association</NavLink></li>
                                    <li><NavLink to='/'>School Houses</NavLink></li>
                                </ul>
                            </li>
                            <li>
                                gallery
                                <ul className="sub-submenu">

                                    <li><NavLink to='/'>Laboratory</NavLink></li>
                                    <li><NavLink to='/'>Computer Lab</NavLink></li>
                                    <li><NavLink to='/'>Library</NavLink></li>
                                </ul>
                            </li>
                            <li>
                                Alumni
                                <ul className="sub-submenu">
                                    <li><NavLink to='/'>Photo Gallery</NavLink></li>
                                    <li><NavLink to='/'>Video Gallery</NavLink></li>
                                </ul>
                            </li>
                            <li>
                                CBSE Disclosure
                                <ul className="sub-submenu">
                                    <li><NavLink to='/'>School Management committee</NavLink></li>
                                </ul>
                            </li> */}
                            <li>
                                Career
                              <ul className="sub-submenu">
                                <li><NavLink to=''>Career</NavLink></li>
                                </ul> 
                            </li>
                            <li>
                                Enquiry
                                <ul className="sub-submenu">
                                    <li><NavLink to='/'>Admission</NavLink></li>
                                </ul>
                            </li>
                            <li>
                                contact
                                <ul className="sub-submenu">
                                    <li><NavLink to='/contactUs'>Contact us</NavLink></li>
                                </ul>
                            </li>

                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Header;