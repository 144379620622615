import React, { useState, useEffect } from 'react'; 
import {getGallery} from'../Service/Api'
import { Link } from 'react-router-dom';
const Gallery = () => { 
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
      useEffect(() => {
      const fetchData = async () => {
           try{
             const galleryData = await getGallery();
             setData(galleryData);
           }catch (error) {
             console.log("Error fetching gallery data:",error)
           }finally{
             setLoading(false)
           }
        
        };
      fetchData();
      }, []);
    const emptyData = [
       { id: 1}
   ];
    return (
        <>
            <div className="row gallery-box">  
            {data?.length > 0 ? data?.filter((div) => div.school === "Senior" && div.category === "Activities").map((item, index) => ( 
            
                <div className="col-lg-4 col-md-4 col-sm-6" key={index}>
                        <div className="gal-img">
                        <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`}   className="img-fluid"/>
                            <div class="description">                              
                            
                                  <Link to="/Activites"><h3>{item.title}</h3><span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/arrow.png"/></span> </Link>
                            </div>
                        </div>
                    </div>
                    )) : emptyData.map((item) => (
                        <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="gal-img">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/Activites4.jpg" />
                            <div class="description">                              
                            
                            <Link to="/Activites"><h3>Activities</h3><span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/arrow.png"/></span> </Link>
                            </div>
                        </div>
                    </div>
                   ))}
                   {data?.length > 0 ? data?.filter((div) => div.school === "Senior" && div.category === "Competitions").map((item, index) => ( 
            
                    <div className="col-lg-4 col-md-4 col-sm-6" key={index}>
                            <div className="gal-img">
                            <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`}   className="img-fluid"/>
                                <div class="description">                              
                                
                                <Link to="/Competition"><h3>{item.title}</h3><span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/arrow.png"/></span> </Link>
                                </div>
                            </div>
                        </div>
                        )) : emptyData.map((item) => (
                            <div className="col-lg-4 col-md-4 col-sm-6">
                            <div className="gal-img">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/Competition_8.jpg" />
                                <div class="description">                              
                                
                                <Link to="/Competition"><h3>Competition</h3><span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/arrow.png"/></span> </Link>
                                </div>
                            </div>
                        </div>
                       ))}
                       {data?.length > 0 ? data?.filter((div) => div.school === "Senior" && div.category === "Assemblies").map((item, index) => ( 
            
                        <div className="col-lg-4 col-md-4 col-sm-6" key={index}>
                                <div className="gal-img">
                                <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`}   className="img-fluid"/>
                                    <div class="description">                              
                                    
                                    <Link to="/Assemblies"><h3>{item.title}</h3><span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/arrow.png"/></span> </Link>
                                    </div>
                                </div>
                            </div>
                            )) : emptyData.map((item) => (
                                <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className="gal-img">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/Assembly6.jpg" />
                                    <div class="description">                              
                                    
                                    <Link to="/Assemblies"><h3>Assemblies</h3><span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/arrow.png"/></span> </Link>
                                    </div>
                                </div>
                            </div>
                           ))}
                           {data?.length > 0 ? data?.filter((div) => div.school === "Senior" && div.category === "Achievements").map((item, index) => ( 
            
                            <div className="col-lg-4 col-md-4 col-sm-6" key={index}>
                                    <div className="gal-img">
                                    <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`}   className="img-fluid"/>
                                        <div class="description">                              
                                        
                                        <Link to="/Achivement"><h3>{item.title}</h3><span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/arrow.png"/></span> </Link>
                                        </div>
                                    </div>
                                </div>
                                )) : emptyData.map((item) => (
                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="gal-img">
                                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/sld7.jpg" />
                                        <div class="description">                              
                                        
                                        <Link to="/Achivement"><h3>Achivement</h3><span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/arrow.png"/></span> </Link>
                                        </div>
                                    </div>
                                </div>
                               ))}
                               {data?.length > 0 ? data?.filter((div) => div.school === "Senior" && div.category === "Celebration").map((item, index) => ( 
            
                                <div className="col-lg-4 col-md-4 col-sm-6" key={index}>
                                        <div className="gal-img">
                                        <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`}   className="img-fluid"/>
                                            <div class="description">                              
                                            
                                            <Link to="/Celebrations"><h3>{item.title}</h3><span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/arrow.png"/></span> </Link>
                                            </div>
                                        </div>
                                    </div>
                                    )) : emptyData.map((item) => (
                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                        <div className="gal-img">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/Competition9.jpg" />
                                            <div class="description">                              
                                            
                                            <Link to="/Celebrations"><h3>Celebrations</h3><span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/arrow.png"/></span> </Link>
                                            </div>
                                        </div>
                                    </div>
                                   ))}
                                   {data?.length > 0 ? data?.filter((div) => div.school === "Senior" && div.category === "Sports").map((item, index) => ( 
            
                                    <div className="col-lg-4 col-md-4 col-sm-6" key={index}>
                                            <div className="gal-img">
                                            <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`}   className="img-fluid"/>
                                                <div class="description">                              
                                                
                                                <Link to="/Sports"><h3>{item.title}</h3><span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/arrow.png"/></span> </Link>
                                                </div>
                                            </div>
                                        </div>
                                        )) : emptyData.map((item) => (
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                            <div className="gal-img">
                                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/Sports13.jpg" />
                                                <div class="description">                              
                                                
                                                <Link to="/Sports"><h3>Sports</h3><span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/TPSP/public/Images/arrow.png"/></span> </Link>
                                                </div>
                                            </div>
                                        </div>
                                       ))}
            </div>
        </>
    )
}
export default Gallery